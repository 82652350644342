import { convertHexToRGBA } from './functions/convertHextoRGBA';

const blackColor = '#000000';
const whiteColor = '#FFFFFF';
const ceruleanColor = '#028FDF';
const foamColor = '#E6F4FC';
const dodgerBlueColor = '#1890FF';
const lochmaraColor = '#007EC5';
const waikawaGrayColor = '#586C93';
const limaColor = '#52C41A';
const sunColor = '#F3802C';
const sunglowColor = '#FFC53D';
const bambooColor = '#D46B08';
const amaranthColor = '#EE294C';
const cupidColor = '#FABEC9';
const pigPinkColor = '#FDE3E8';
const sunsetOrangeColor = '#FF4D4F';
const linenColor = '#FBE8E5';
const crimsonColor = '#CF1322';
const violetRedColor = '#EE2979';
const razzmatazzColor = '#D70C5E';
const pickledBluewoodColor = '#2E3F57';
const midnightColor = '#001529';
const stratosColor = '#030031';
const buntingColor = '#151B44';
const cloudBurstColor = '#222851';
const botticelliColor = '#D2D9E7';
const polarColor = '#D9F7F5';
const jungleGreenColor = '#24A39A';
const mischkaColor = '#DADDE2';
const goblinColor = '#368636';
const panacheColor = '#ECF6EC';
const linkWaterColor = '#DFEEF7';
const rockBlueColor = '#9EABCB';
const pictonBlueColor = '#28AAEB';
const athensGrayColor = '#F3F5F8';
const porcelainColor = '#EAEDEF';
const aquaHazeColor = '#F8FAFB';
const ironColor = '#DDE0E2';
const geyserColor = '#CED6DD';
const wildSandColor = '#F5F5F5';
const mysticColor = '#EBEEF4';
const athensGray = '#F8F9FA';
const pompadourColor = '#630054';
const freshEggplantColor = '#9A0068';
const roseColor = '#FF008C';
const amourColor = '#F3D2EE';
const regalBlueColor = '#013477';
const sanMarinoColor = '#3A6CA8';
const danubeColor = '#6298C6';
const periwinkleGrayColor = '#B9C6E7';
const astronautBlueColor = '#024062';
const jellyBeanColor = '#27768E';
const zanahColor = '#DAEDDA';
const blueMargueriteColor = '#706AC1';
const titanWhiteColor = '#EDECFE';
const lightBlueColor = '#B7DEF3';
const webOrangeColor = '#FFA600';
const caribbeanGreenColor = '#01CAA8';
const chambrayColor = '#2F4B7C';
const crustaColor = '#FF7D3F';
const flamingoColor = '#F15642';
const tapestryColor = '#A05095';
const butterflyBushColor = '#675291';
const bigStoneColor = '#172334';
const blueChillColor = '#098DA2';
const laPalmaColor = '#169A16';
const marinerColor = '#3678D0';
const deYorkColor = '#94D094';
const coconutWhite = '#F4F7FE';
const bridesmaidColor = '#FEF2EA';
const tropicalBlueColor = '#CCE9F9';
const pipinColor = '#FFE0E6';
const serenadeColor = '#FFF5E5';
const christineColor = '#E3660A';

export default {
  'primary-color': ceruleanColor,
  'primary-color-light': foamColor,
  'primary-color-medium': dodgerBlueColor,
  'primary-color-dark': lochmaraColor,
  'primary-color-opacity': convertHexToRGBA(dodgerBlueColor, 60),
  'secondary-color': waikawaGrayColor,
  'secondary-color-light': waikawaGrayColor,
  'secondary-color-dark': waikawaGrayColor,
  'tertiary-color': laPalmaColor,
  'tertiary-disabled-color': deYorkColor,
  'link-color': dodgerBlueColor,
  'success-color': limaColor,
  'success-color-light': zanahColor,
  'warning-color': sunColor,
  'warning-color-light': sunglowColor,
  'warning-color-dark': bambooColor,
  'warning-color-alert': bridesmaidColor,
  'error-bg-light': pigPinkColor,
  'error-progress': cupidColor,
  'error-color': amaranthColor,
  'error-chip-bg-light': pipinColor,
  'error-color-opacity': convertHexToRGBA(amaranthColor, 10),
  'error-color-opacity-darken': convertHexToRGBA(amaranthColor, 20),
  'error-color-alert': linenColor,
  'error-color-light': sunsetOrangeColor,
  'error-color-dark': crimsonColor,
  'error-color-pink': violetRedColor,
  'error-color-dark-pink': razzmatazzColor,
  'danger-color-light': bridesmaidColor,
  'font-size-base': '14px',
  'heading-color': pickledBluewoodColor,
  'text-color': pickledBluewoodColor,
  'text-color-secondary': convertHexToRGBA(blackColor, 45),
  'disabled-color': convertHexToRGBA(pickledBluewoodColor, 60),
  'disabled-color-light': convertHexToRGBA(blackColor, 5),
  'border-radius-base': '3px',
  'border-color-base': botticelliColor,
  'border-color-light-blue': lightBlueColor,
  'box-shadow-base': `0 3px 6px -4px ${convertHexToRGBA(
    blackColor,
    12
  )}), 0 6px 16px 0 ${convertHexToRGBA(
    blackColor,
    8
  )}), 0 9px 28px 8px ${convertHexToRGBA(blackColor, 5)})`,
  'input-focus-shadow': `0 0 0 2px ${convertHexToRGBA(ceruleanColor, 20)}`, // 20% of primary color
  'input-focus-shadow-error': `0 0 0 2px ${convertHexToRGBA(
    amaranthColor,
    20
  )}`, // 20% of error-color
  'black-color': blackColor,
  'white-color': whiteColor,
  'menu-active-bg': convertHexToRGBA(dodgerBlueColor, 10), // 10% of primary-color
  'background-color-dark': midnightColor,
  'default-red': violetRedColor,
  'sider-bg': stratosColor,
  'sider-bg-secondary': buntingColor,
  'sider-bg-third': cloudBurstColor,
  'placeholder-text-color': convertHexToRGBA(pickledBluewoodColor, 60),
  'placeholder-border-color': botticelliColor,
  'placeholder-border-color-light': convertHexToRGBA(botticelliColor, 35),
  'primary-tag-bg-color': polarColor,
  'primary-tag-font-color': jungleGreenColor,
  'secondary-tag-bg-color': mischkaColor,
  'secondary-tag-font-color': pickledBluewoodColor,
  'tertiary-tag-bg-color': linkWaterColor,
  'tertiary-tag-font-color': ceruleanColor,
  'layout-trigger-color': rockBlueColor,
  'ant-hover-color': pictonBlueColor,
  'dropdown-hover-gray': athensGrayColor,
  'dropdown-active-gray': porcelainColor,
  'disabled-input-gray': aquaHazeColor,
  'disabled-switch-gray': rockBlueColor,
  'table-row-selected': ironColor,
  'border-secondary-color': geyserColor,
  'disabled-ant-bg-color': wildSandColor,
  'background-color-light': mysticColor,
  'checkbox-border-color': rockBlueColor,
  'chips-color': goblinColor,
  'chips-success-background-opacity': panacheColor,
  'chips-success-background-dark-opacity': convertHexToRGBA(goblinColor, 15),
  'chips-greyed-background-opacity': convertHexToRGBA(porcelainColor, 51),
  'form-builder-background': athensGray,
  'purple-color': pompadourColor,
  'purple-active-color': freshEggplantColor,
  'pink-color': roseColor,
  'pink-text-color': amourColor,
  'dark-blue-color': regalBlueColor,
  'dark-blue-opacity-color': convertHexToRGBA(danubeColor, 20),
  'dark-blue-separator-color': sanMarinoColor,
  'light-blue-color': danubeColor,
  'light-blue-text-color': periwinkleGrayColor,
  'sea-blue-color': astronautBlueColor,
  'sea-blue-light-color': jellyBeanColor,
  'alert-info-bg': convertHexToRGBA(ceruleanColor, 20),
  'field-secondary-tag-font-color': blueMargueriteColor,
  'field-secondary-tag-bg-color': titanWhiteColor,
  'avatar-color-0': webOrangeColor,
  'avatar-color-1': crustaColor,
  'avatar-color-2': flamingoColor,
  'avatar-color-3': violetRedColor,
  'avatar-color-4': tapestryColor,
  'avatar-color-5': butterflyBushColor,
  'avatar-color-6': chambrayColor,
  'avatar-color-7': bigStoneColor,
  'avatar-color-8': ceruleanColor,
  'avatar-color-9': blueChillColor,
  'avatar-color-10': caribbeanGreenColor,
  'avatar-color-11': laPalmaColor,
  'section-header-bg': marinerColor,
  'section-content-bg': coconutWhite,
  'info-bg': tropicalBlueColor,
  'chips-warning-color': christineColor,
  'chips-warning-bg-color': serenadeColor,
  'font-family-consolas': 'Consolas',
};
