import { AlertProps } from 'antd/lib/alert';
import { ThemeState } from 'store/reducers/types/theme.types';
import { AlertColors } from './types';

export const getAlertColors = (
  type: AlertProps['type'],
  theme: ThemeState
): AlertColors => {
  switch (type) {
    case 'success':
      return {
        color: theme['chips-color'],
        backgroundColor: theme['success-color-light'],
      };
    case 'error':
      return {
        color: theme['error-color'],
        backgroundColor: theme['error-color-alert'],
      };
    case 'warning':
      return {
        color: theme['warning-color'],
        backgroundColor: theme['warning-color-alert'],
      };
    default:
      return {
        color: theme['primary-color'],
        backgroundColor: theme['alert-info-bg'],
      };
  }
};
